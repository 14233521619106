import React from "react";
import {
  MDBContainer,
  MDBCol,
  MDBLightbox,
  MDBLightboxItem,
  MDBRow,
} from "mdb-react-ui-kit";
// import 'mdb-react-ui-kit/dist/css/mdb.min.css';

export default function Certified() {
  return (
    <div></div>
    // <MDBLightbox>
    //   <MDBRow>
    //     <MDBCol lg="6">
    //       <MDBLightboxItem
    //         src="https://mdbootstrap.com/img/Photos/Thumbnails/Slides/1.webp"
    //         fullscreenSrc="https://mdbootstrap.com/img/Photos/Slides/1.webp"
    //         className="w-100 mb-2 mb-md-4"
    //       />
    //       <MDBLightboxItem
    //         src="https://mdbootstrap.com/img/Photos/Thumbnails/Square/1.webp"
    //         fullscreenSrc="https://mdbootstrap.com/img/Photos/Square/1.webp"
    //         className="w-100"
    //       />
    //     </MDBCol>
    //     <MDBCol lg="6">
    //       <MDBLightboxItem
    //         src="https://mdbootstrap.com/img/Photos/Thumbnails/Vertical/1.webp"
    //         fullscreenSrc="https://mdbootstrap.com/img/Photos/Vertical/1.webp"
    //         className="w-100"
    //       />
    //     </MDBCol>
    //   </MDBRow>
    // </MDBLightbox>
  );
}
